import { setRootTaxSysIframeUrl } from '@grantstreet/psc-environment/environment.js'
import type { Store } from 'vuex'

export function setupFullPublicSite (store: Store<Record<string, unknown>>) {
  if (process.env?.GSG_TAX_CBS_SERVICE) {
    setRootTaxSysIframeUrl(process.env.GSG_TAX_CBS_SERVICE)
  }
  store.dispatch('PayHub/setInstallFlags', {
    enableHeader: true,
    enableFooter: true,
    showAnnouncements: true,
    enableMyForms: true,
    enableHomePage: true,
    enableFloatingCart: true,
    enableMySettingsModification: true,
  })
}
