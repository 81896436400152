import { joinUrl } from '@grantstreet/psc-js/utils/urls.js'
// PSC ENVIRONMENT HELPERS
//
// This is the new official way to handle environments in PSC apps.
//
// NOTE: Many PSC apps still use legacy environment-detection methods, but we
// are migrating those to this new method. If you are modifying an existing app
// you should use whichever environment method it already uses. If you are
// creating a new app you should use this new method.
//
// How to use this file:
//
// 1. As soon as your app/widget initializes, determine the current environment
//    using one of the methods outlined in this page:
//    https://support.grantstreet.com/wiki/display/TECH/Front-End+Best+Practices
//
// 2. Validate the environment from step 1 by passing it to
//    isValidEnvironment(). If it is invalid, show a load/site error.
//
// 3. Pass the now-validated environment to setEnvironment(). This will set the
//    value of the GSG_ENVIRONMENT variable.
//
// 4. In your app, wherever you need to know the current environment (e.g., to
//    make an API request), import GSG_ENVIRONMENT and use it as a string.
//
// Supported PSC environments:
//   - dev
//   - test
//   - beta
//   - stage
//   - demo
//   - prod
//
// Note, the `dev` environment will behave like `test` when talking to back-end
// services, since there are no deployed `dev` PSC services.
//
// Avoid doing URL matching to determine the environment - that is not scalable
// when our widgets are embedded on arbitrary sites.
//

/**
 * A string of the current environment ('dev', 'test', 'stage', 'beta', 'demo',
 * or 'prod'), without the data center number
 * @type string
 */
export let GSG_ENVIRONMENT

/**
 * A string of the base GovHub URL for the current environment
 * @type string
 */
export let baseGovHubUrl

/**
 * A string of the base PayHub URL for the current environment
 * @type string
 */
export let basePayHubUrl

/**
 * A string of the base County Taxes URL for the current environment
 * @type string
 */
export let baseCountyTaxesUrl

/**
 * A string of the base E-Wallet URL for the current environment
 * @type string
 */
export let baseEWalletUrl

/**
 * A string of the base DataVault (DV) URL for the current environment
 * @type string
 */
export let baseDVUrl

/**
 * The environment to find PSC services. Separate from {@link
 * GSG_ENVIRONMENT} because the `dev` environment should behave
 * like `test` when talking to back-end services, since there
 * are no deployed `dev` PSC services.
 */
export let pscServiceEnvironment

export const environmentStrings = Object.freeze({
  dev: 'dev',
  test: 'test',
  stage: 'stage',
  beta: 'beta',
  demo: 'demo',
  prod: 'prod',
})

// Returns a boolean indicating whether the passed environment is one of the
// valid PSC environments.
export const isValidEnvironment = environment => typeof environmentStrings[environment] === 'string'

const getPscServiceEnvironment = (env = GSG_ENVIRONMENT) => {
  return env === 'dev' ? 'test' : env
}

export const getGovHubUrl = (env = GSG_ENVIRONMENT) => {
  return env === 'prod'
    ? 'https://govhub.com'
    : `https://${getPscServiceEnvironment(env)}.govhub.com`
}

export const getPayHubUrl = (env = GSG_ENVIRONMENT) => {
  return env === 'prod'
    ? 'https://pay-hub.net'
    : `https://${getPscServiceEnvironment(env)}.pay-hub.net`
}

export const getCountyTaxesUrl = (env = GSG_ENVIRONMENT) => {
  return env === 'prod'
    ? 'https://county-taxes.net'
    : `https://${getPscServiceEnvironment(env)}.county-taxes.net`
}

/**
 * Sets the global {@link GSG_ENVIRONMENT} variable to the
 * passed environment.
 * @throws Error if the environment is invalid.
 */
export const setEnvironment = environment => {
  if (!isValidEnvironment(environment)) {
    throw new Error(`"${environment}" is not a valid environment`)
  }

  GSG_ENVIRONMENT = environment

  if (process.env?.GSG_ENVDC) {
    GSG_ENVIRONMENT = getEnvFromEnvdc(process.env.GSG_ENVDC)
  }

  pscServiceEnvironment = getPscServiceEnvironment()

  baseGovHubUrl = getGovHubUrl()
  basePayHubUrl = getPayHubUrl()
  baseCountyTaxesUrl = getCountyTaxesUrl()

  baseEWalletUrl = GSG_ENVIRONMENT === 'prod'
    ? 'https://e-wallet.grantstreet.com'
    : `https://${pscServiceEnvironment}-e-wallet.grantstreet.com`

  // Keep in sync with getCardInputUrl in EW. If the slugs change then you'll
  // need to look at them both.
  // e-wallet-vue/unit/tests/unit/getCardInputUrl.spec.js has tests that cover
  // this portion.
  if (GSG_ENVIRONMENT === 'prod') {
    if (
      // In the context of e2e tests (which don't have a window), default to
      // prod3
      typeof window !== 'undefined' &&
      /^www2/.test(window.location.hostname || '')
    ) {
      baseDVUrl = 'https://www2.vault.county-taxes.com/vault'
    }
    else {
      baseDVUrl = 'https://vault.county-taxes.com/vault'
    }
  }
  else if (GSG_ENVIRONMENT === 'dev' || GSG_ENVIRONMENT === 'test') {
    baseDVUrl = `https://${pscServiceEnvironment}.vault.grantstreet.com/vault`
  }
  else {
    baseDVUrl = `https://${pscServiceEnvironment !== 'demo' ? pscServiceEnvironment : 'stage'}.vault.county-taxes.com/vault`
  }
}

export const needsBasicAuth = (environment = GSG_ENVIRONMENT, client) => {
  return environment === 'beta' || environment === 'demo' || (environment === 'prod' && client === 'sunshine')
}

/**
 * If set, this URL will be used for getTaxsysUrl. Used by the GovHub
 * installer to allow testing local TaxSys sandbox changes in the
 * embedded public site wrapper.
 *
 * @type string
 */
let localTaxSysSandboxUrl
/**
 * If set, this will be used as the base domain of the url returned
 * by getTaxsysUrl. Used by the GovHub installer to fix CORS issues
 * with embedded site sandboxes and to handle the GSG_TAX_CBS_SERVICE
 * service discovery environment variable.
 *
 * If needed, the taxsys client ID will be removed from this URL and
 * added back when we know which client to use.
 *
 * @type string
 */
let rootTaxSysIframeUrl

/**
 * Set the local TaxSys sandbox URL.
 * @see localTaxSysSandboxUrl
 */
export const setLocalTaxSysSandboxUrl = url => {
  localTaxSysSandboxUrl = url
}

/**
 * Set the root TaxSys iframe URL.
 * @see rootTaxSysIframeUrl
 */
export const setRootTaxSysIframeUrl = url => {
  // remove trailing '/' if present
  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }
  // Remove current client ID, if present. We'll add the client ID when
  // we know it, later.
  const match = url.match(/:\/\/[^-]+-[^-]+(-[^.]+)\.[^:/.]+\./)
  if (match) {
    url = url.replace(match[1], '')
  }
  // Remove trailing `/iframe-taxsys`, if accidentally present
  url = url.replace('/iframe-taxsys', '')
  rootTaxSysIframeUrl = url
}

/**
 * Get the URL for the TaxSys Iframe.
 *
 * @param {string} taxsysClientId - The TaxSys client ID from Site
 * Settings. It's critical that this use the this.config.taxsys?.taxsysClientId
 * setting since GH's client id's match PEx, not TaxSys's subdomains. The
 * difference is in presence and order of state prefix.
 * @param {boolean} forwardTsDomain - If true, uses "county-taxes.net"
 * as the root TaxSys Iframe URL. This domain is used to host the TaxSys
 * public site and set up to forward requests for `/iframe-taxsys` to
 * "county-taxes.com" to work around CORS issues.
 * @param {string} env - The environment to use. Defaults to the current
 * environment.
 */
export const getTaxsysUrl = (taxsysClientId, forwardTsDomain, env = GSG_ENVIRONMENT) => {
  if (!pscServiceEnvironment) {
    throw new Error('Environment is not initialized')
  }

  // Allow overriding of TaxSys URL for developer sandboxes.
  if (localTaxSysSandboxUrl && env !== 'prod') {
    // transform the taxsys url to the needed format
    // extract tcbdev010-dev3-sanbernardino-ca.grantstreet.com
    // from https://tcbdev010-dev3-sanbernardino-ca.grantstreet.com:11353/
    const subUrl = localTaxSysSandboxUrl.match('https://(.*):')[1]
    const url = joinUrl(localTaxSysSandboxUrl, `iframe-taxsys/${subUrl}`)
    return url
  }

  // Work around CORS issues in embedded public sites and auto-linked
  // developer sandboxes.
  // To allow this to be a proxy, we must allow the empty string here.
  if (typeof rootTaxSysIframeUrl === 'string') {
    let rootUrl = rootTaxSysIframeUrl
    // Add the client ID to the hostname, only necessary for sandboxes
    // which only get hosted on grantstreet.com
    if (rootUrl.match(/-dev\d+\.grantstreet\.com:\d+/)) {
      rootUrl = rootUrl.replace(/\./, `-${taxsysClientId}.`)
    }
    return `${rootUrl}/iframe-taxsys/${pscServiceEnvironment}-${taxsysClientId}.county-taxes.com`
  }

  if (forwardTsDomain === true) {
    // We need to make sure that we load county-taxes.net iframes from the same
    // origin that we're currently on to avoid cross origin issues.
    const isGovHub = /govhub\.com/.test(window.location.origin)
    let currentDomain = 'county-taxes.net'

    if (isGovHub) {
      currentDomain = 'govhub.com'
    }

    // TS uses BigIP to forward these requests from county-taxes.net to
    // county-taxes.com in order to avoid cors issues. This is behind an ld flag
    // See PSC-18103 for deets
    return env === 'prod'
      ? `https://${currentDomain}/iframe-taxsys/${taxsysClientId}.county-taxes.com`
      : `https://${pscServiceEnvironment}.${currentDomain}/iframe-taxsys/${pscServiceEnvironment}-${taxsysClientId}.county-taxes.com`
  }

  return env === 'prod'
    ? `https://${taxsysClientId}.county-taxes.com`
    : `https://${pscServiceEnvironment}-${taxsysClientId}.county-taxes.com`
}

// Returns a boolean indicating whether the current environment is prod
export const isProd = () => {
  if (!GSG_ENVIRONMENT) {
    throw new Error('Tried to access isProd() before GSG_ENVIRONMENT was set')
  }
  return GSG_ENVIRONMENT === 'prod'
}

function getEnvFromEnvdc (envdc) {
  const matches = /^([a-z]+)\d$/.exec(envdc)
  if (matches?.length > 0) {
    return matches[1]
  }

  throw new Error(`Unknown envdc "${envdc}"`)
}

// This is used by initWidgetEnvWithFallback to avoid re-logging the fact that a
// site is not passing an environment to Kibana when multiple widgets are being
// attached on the same page. Currently this only applies to Announcements.
const alreadyLoggedLegacy = {}

// Sets the current environment for an embeddable PSC widget, falling back to an
// environment detected through legacy means if necessary. This is a temporary
// helper function being used while external "parent" apps are in the process of
// passing explicit environment parameters to PSC widgets. If an explicit
// environment was not passed, this will use the passed legacy environment and
// log the incident to Kibana.
//
// This does not need to be used for single-page applications because those can
// flip over to using the /environment endpoint method in a single release
// without legacy support.
//
// TODO PSC-9164: Remove this when all parents are explicitly passing an
// environment.
export const initWidgetEnvWithFallback = ({
  explicitEnvironment,
  urlForLegacyEnvironment,
  legacyEnvironmentOverride,
  diagApp,
  diagSite,
  // This is injected to avoid dependency cycles:
  RequestApi,
  exceptionLogger,
}) => {
  if (typeof explicitEnvironment === 'undefined') {
    const legacyEnvironment = legacyEnvironmentOverride ||
      getEnvFromUrlLegacy(urlForLegacyEnvironment)

    // This means the parent site is not yet explicitly passing the
    // `environment` attach parameter to the current widget, so use the widget's
    // legacy environment-detection method (likely URL-matching). This can be
    // removed once all sites using the widget are passing the environment.
    setEnvironment(legacyEnvironment)

    // If we have already logged the legacy notice to Kibana for this app, skip
    // logging it again.
    if (alreadyLoggedLegacy[diagApp]) {
      return
    }
    alreadyLoggedLegacy[diagApp] = true

    // Log the fact that this site isn't passing the environment to Kibana. This
    // will help PSC know when all sites are using the new explicit environment
    // parameter so we can remove the legacy fallback.
    const requestApi = new RequestApi({
      app: diagApp,
      env: legacyEnvironment,
      exceptionLogger,
    })
    requestApi.diag({
      ...diagSite ? { site: diagSite } : {},
      diagnostics: {
        usingLegacyEnvironment: true,
      },
    })
    return
  }
  if (!isValidEnvironment(explicitEnvironment)) {
    throw new Error(`Invalid "environment" parameter. Passed value: "${explicitEnvironment}". Expected "dev", "test", "stage", "beta", "demo", or "prod".`)
  }

  setEnvironment(explicitEnvironment)
}

/**
 * This is a legacy method of determining the current environment based on url
 * matching. It is not scalable and should only be used via
 * initWidgetEnvWithFallback, which should only be used for EXISTING widgets
 * that are in the process of being migrated to use explicit environments.
 */
function getEnvFromUrlLegacy (url) {
  if (!url) {
    // We won't have a url for isProd() checks in E2E testing
    if (typeof window === 'undefined') {
      return 'dev'
    }
    else {
      url = window.location.hostname
    }
  }
  // If we were passed in a url, make sure the protocol is stripped
  else {
    url = url.replace(/^https?:\/\//, '')
  }

  if (url === 'pay-hub.net' ||
    url === 'e-wallet.grantstreet.com' ||
    url === 'payment-express.net' ||
    url === 'renewexpress.com' ||
    url === 'county-taxes.net') {
    return 'prod'
  }

  if (/^govhub.com/.test(url)) {
    return 'prod'
  }

  if (/^[a-z]+(?:-[a-z]{2})?\.(?:taxsys\.net|county-taxes\.com)/.test(url)) {
    return 'prod'
  }

  if (/netlify\.(app|com)/.test(url)) {
    return 'beta'
  }

  // Parse out the environment
  // Matches  (<env>)-(payhub|e-wallet).grantstreet.com
  //          (<env>).pay-hub.net
  //          (<env>).payment-express.net
  //          (<env>).renewexpress.com
  //          (<env>).county-taxes.net
  //          (<env>)-<county>.taxys.net
  //      or  (<env>)-<county>.county-taxes.com
  const matches = /(?:^|\.)([a-z]+)\d?(?:-(?:payhub|e-wallet)\.grantstreet\.com|\.pay-hub\.net|\.govhub\.com.*|\.payment-express\.net|\.renewexpress\.com|\.county-taxes\.net|-[a-z]+(?:-[a-z]{2})?\.(?:taxsys\.net|county-taxes\.com))/g.exec(url)

  if (matches && matches.length === 2) {
    // The 2 from www2 has already been parsed out by the above regex
    return matches[1] === 'www' ? 'prod' : matches[1]
  }

  // That won't have matched for WDS or sandboxes
  return 'dev'
}

// Returns a boolean indicating if the url would take the user to a govhub page.
export const isUrlInternal = (url) => {
  return url.match(/^https:\/\/(.+)?(govhub\.com|county-taxes\.net|pay-hub\.net)/)
}
