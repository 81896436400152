import { getClientsAndSites, loadConfig } from '@grantstreet/psc-config'
import safeLocalStorage from '@grantstreet/psc-js/utils/safe-local-storage.js'

// Parses the client and site from the URL. We can't use vue-router for this
// because it is not fully loaded until the app is mounted. The `site` returned
// might be undefined or not actually a site if this is a client-only-url site
// (e.g., /sacramento or /sacramento/utilities-search).
export function clientAndSiteFromUrl () {
  let path: string | null = window.location.pathname

  if (path === '/') {
    // Try to get to the last-used client/site and hydrate the url
    path = safeLocalStorage.getItem('lastPayHubPath')
    if (path) {
      window.history.replaceState(null, '', path)
    }
  }

  // We don't have anything to return
  const parts = window.location.pathname.split('/')
  if (parts.length < 2) {
    return {}
  }

  // Stash the client/site and return it
  safeLocalStorage.setItem('lastPayHubPath', path)

  // Keep in sync with @grantstreet/psc-js/utils/routing.js>formatParams()
  return {
    client: (parts[1] || '').toLowerCase(),
    site: (parts[2] || '').toLowerCase(),
  }
}

export async function setupConfig () {
  const { client, site } = clientAndSiteFromUrl()
  await getClientsAndSites()
  await loadConfig({ client, site })
}
