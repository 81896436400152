import { createApp } from 'vue'
import {
  App,
  clientAndSiteFromUrl,
  setupCallbackActions as setupGovHubCallbackActions,
  initEWalletHelpers,
  initLoginHelpers,
  logBootTime,
  setupCartIdFromRedirectUrl,
  setupConfig,
  setupEmbeddedLoginOptions,
  setupFullLoginOptions,
  setupEmbeddedParentWhisperer,
  setupEmbeddedPublicSite,
  setupEnvironment,
  setupFormKitConfig,
  setupFullPublicSite,
  setupGoogleAnalyticsConfig,
  setupPublicSite,
  stubLoginHelpers,
  sentryException,
  useEWalletHelpers,
  vueErrorHandler,
} from '@grantstreet/govhub-vue'
import type { GoogleAnalytics } from '@grantstreet/govhub-vue'
import { installConfig, configGetters, configState, setLDMetadata } from '@grantstreet/psc-config'
import VueGtag, { event as logAnalyticsEvent } from 'vue-gtag'
import { createHead } from '@unhead/vue'
import { i18n, addI18n, mergeLangs } from '@grantstreet/psc-vue/utils/i18n.ts'
import { plugin as formKitPlugin } from '@formkit/vue'
import { createVueWait } from 'vue-wait'
import VueSignaturePad from 'vue-signature-pad'
import LoadFailure from './views/LoadFailure.vue'
import en from './translations/en.ts'
import es from './translations/es.ts'
import EventBus from '@grantstreet/psc-vue/utils/event-bus.ts'
import createRouter from './router.ts'

// Modules
import { createCallbackActions, useCallbackActions } from '@grantstreet/callback-actions'
import RouterSync from '@grantstreet/router-sync'
import Announcements from '@grantstreet/announcements-vue'
import MyPayments from '@grantstreet/my-payments-vue'
import MyDashboard from '@grantstreet/psp-my-dashboard'
import Help from '@grantstreet/help-vue'
import SchedPay, { createCallbackActions as createSchedPayCallbackActions } from '@grantstreet/schedpay-vue'
import IndexSearch from '@grantstreet/index-search'
import Payables from '@grantstreet/payables'
import EBillingPublic from '@grantstreet/e-billing-public'
import UserVerificationPublic from '@grantstreet/user-verification-public'
import DeliveryMethod from '@grantstreet/delivery-method'
import FormsPublic from '@grantstreet/forms-public-legacy'
import Donations from '@grantstreet/donations'
import Cart, { createCallbackActions as createCartCallbackActions } from '@grantstreet/cart-vue'
import eWallet from '@grantstreet/e-wallet-vue'
import { GSG_ENVIRONMENT } from '@grantstreet/psc-environment'

// Login
import {
  createUserPlugin,
  useGsgUser,
  type User,
} from '@grantstreet/user'
import {
  handleOidcCallbackPage,
  createLogin,
  useLogin as useLoginUtils,
} from '@grantstreet/login'
import LoginApi from '@grantstreet/login-api'

// Other
import VueSVGIcon from '@grantstreet/bootstrap/icons/vue-svgicon.js'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import sanitizeConfig from '@grantstreet/psc-js/utils/sanitize.js'

try {
  const app = createApp(App)
  const {
    embeddedAttachPromise,
    embeddedParentWhisperer,
  } = setupEmbeddedParentWhisperer()
  await setupEnvironment(sentryException)

  // This is required by a lot of things so it needs to be initialized first
  const gsgUserPlugin = createUserPlugin()
  app.use(gsgUserPlugin, {})
  const { user } = useGsgUser(gsgUserPlugin)

  const store = setupPublicSite(app, user)

  // Other things need these helpers so set them up right away
  initEWalletHelpers(store)

  app.use(VueSVGIcon, { isStroke: true })
  app.use(VueDOMPurifyHTML, sanitizeConfig)

  // Before anything else, check if we're on a login callback URL (e.g.,
  // govhub.com/callback), and resolve that if so. This is done before
  // everything because the callbacks handler may need to redirect to the path
  // the login process started from.
  const loginSucceeded = await handleOidcCallbackPage({ env: GSG_ENVIRONMENT, oidcClient: 'payhub-spa' })
  const logRequest = (...args) => store.dispatch('PayHub/logRequest', ...args)
  const logDiagnostics = (...args) => store.dispatch('PayHub/logDiagnostics', ...args)
  installConfig({ logDiagnostics })
  await setupConfig()
  const config = configState.config
  // If EPS is ever put into a separate build this can be determined from some
  // other source which would allow us to install login right away
  const embeddedMode = configGetters?.useEmbeddedPublicSite
  let googleAnalyticsForEPS : GoogleAnalytics | Record<string, never> = {}
  if (embeddedMode) {
    googleAnalyticsForEPS = await setupEmbeddedPublicSite(app, { store, bus: EventBus, embeddedAttachPromise, embeddedParentWhisperer })
  }
  else {
    setupFullPublicSite(store)
  }

  const router = createRouter(store)

  // Only install login if it's configured
  // TODO: Refactor the login installation logic into its own plugin
  store.commit('API/setLoginApi', LoginApi)
  const { useEbilling, useUserVerification, useDelivery, useForms, useLogin } = configGetters
  if (!useLogin) {
    stubLoginHelpers()
    store.commit('PayHub/setAuthPromise', Promise.resolve())
  }
  else {
    const gsgLogin = createLogin()
    app.use(gsgLogin, embeddedMode ? setupEmbeddedLoginOptions(user, embeddedParentWhisperer) : setupFullLoginOptions(user, config))
    const {
      initAuth,
      authPromise,
      beforeLogin,
      onUserSignedOut,
      onLoginFailure,
    } = useLoginUtils(gsgLogin)
    store.commit('PayHub/setAuthPromise', authPromise)

    beforeLogin(
      () => logAnalyticsEvent('Login Attempt', { 'event_category': 'PayHub' }),
    )
    onUserSignedOut(
      () => logAnalyticsEvent('Logout', { 'event_category': 'Logout' }),
    )
    onLoginFailure(error => {
      if (error) {
        logAnalyticsEvent(error.message, { 'event_category': 'Login Failure' })
      }
    })
    initAuth()
    initLoginHelpers(gsgLogin)
    useLoginUtils(app).onUserLoaded((user: User) => {
      if (user.language) {
        store.dispatch('PayHub/setLocale', {
          locale: user.language,
          updateUser: false,
        })
      }

      if (user?.id) {
        logAnalyticsEvent(user.id, { 'event_category': 'Login Success' })
      }

      // Used for LG flag loading
      setLDMetadata(user)
    })
  }

  const callbackActionsPlugin = createCallbackActions()
  app.use(callbackActionsPlugin, {
    // Persistent id unique to the application (not a secret)
    applicationId: 'GovHub-afeb442e-a413-4bfe-ba0c-fd84610ca047',
  })
  const {
    registerCallbacks,
    runCallbacks,
  } = useCallbackActions(callbackActionsPlugin)
  app.use(RouterSync, { store, router })
  if (!embeddedMode) {
    app.use(Announcements, { store, bus: EventBus })
  }
  app.use(MyPayments, { store, sentryException })
  app.use(MyDashboard, { store, router, bus: EventBus, sentryException })
  app.use(Help, { bus: EventBus, sentryException, store })
  app.use(SchedPay, { isAdmin: false, store })
  registerCallbacks(createSchedPayCallbackActions(app, { store }))
  app.use(IndexSearch, { store })
  app.use(Payables, { bus: EventBus, config, logDiagnostics })

  if (useEbilling) {
    app.use(EBillingPublic, { config, logRequest })
  }
  if (useUserVerification) {
    app.use(UserVerificationPublic, { config, logRequest })
  }
  if (useDelivery) {
    app.use(DeliveryMethod, { config })
  }
  if (useForms) {
    app.use(FormsPublic, { config })
  }
  if (config.renewexpress?.enableCharitableDonations) {
    app.use(Donations, { config })
  }

  registerCallbacks(setupGovHubCallbackActions(app, { store, router }))
  const cartRedirectUrlPromise = setupCartIdFromRedirectUrl(store)
  registerCallbacks(createCartCallbackActions(app, { store }))

  EventBus.$on('ewallet.authProbeFailed', () => {
    router.push({ name: 'networkError' })
  })

  app
    .use(vueErrorHandler)
    .use(store)
    .use(i18n)
    .use(router)
    .use(createHead())
    .use(VueSignaturePad)
    .use(
      VueGtag,
      setupGoogleAnalyticsConfig(googleAnalyticsForEPS),
      router,
    )
    .use(formKitPlugin, setupFormKitConfig(i18n.global.locale))
    .use(createVueWait({ useVuex: true }))
    .use(Cart, { cartId: await cartRedirectUrlPromise, store, bus: EventBus })
    .use(eWallet, {
      store,
      bus: EventBus,
      supportsLogin: useLogin,
      initializeModule: useEWalletHelpers().initializeEWallet,
    })
    .mount('#app')
  logBootTime(store)

  await runCallbacks()

  // This is used to grab the client/site from the url instead of the config
  // since sometimes the site can be returned as undefined for client-only sites.
  const { client, site } = clientAndSiteFromUrl()
  if (!loginSucceeded) {
    router.replace({
      name: 'login-error',
      params: {
        client,
        site,
      },
    })
  }

  // If we've been redirected to a pre-filled cart, jump to
  // the checkout page
  if (await cartRedirectUrlPromise) {
    // Per the docs on clientAndSiteFromUrl(), `site` is sometimes a bogus
    // value: for instance, for redirects on client-only sites, it's
    // `redirect`. Rather than adding special handing to the router for this
    // case, simply don't pass the bogus site.
    const params: {
      isRedirect: number
      client: string | undefined
      site?: string
    } = {
      isRedirect: 1,
      client,
    }

    // Do pass the site on redirect if it is real though
    if (!(config.useClientOnlyUrl && config.site !== site)) {
      params.site = site
    }

    router.replace({
      name: 'checkout',
      params,
    })
  }
}
catch (error) {
  console.error(error)
  sentryException(error as Error)
  const app = createApp(LoadFailure)
  app.use(vueErrorHandler)
    .use(i18n)
    .mount('#app')
}
finally {
  addI18n(mergeLangs({ en, es }), sentryException)
}
