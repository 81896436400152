import { normalizePayableSource } from '@grantstreet/payables'
import { configGetters, configState } from '@grantstreet/psc-config'
import type { createRouter, RouteLocationNormalized } from 'vue-router'
import { isProd, needsBasicAuth, GSG_ENVIRONMENT } from '@grantstreet/psc-environment'
import { formatParams } from '@grantstreet/psc-js/utils/routing.js'
import { i18n } from '@grantstreet/psc-vue/utils/i18n.ts'

const configRouteKeys = [
  'useMyDashboard',
  'useMySavedItems',
  'useMyPayments',
  'useLogin',
  'useForms',
  'useBtExpress',
  'useReports',
] as const

type Route = Parameters<typeof createRouter>[0]['routes'][number]
type RouteDefinitions = Record<typeof configRouteKeys[number], Route>

export const configRoutes = (routeDefinitions: Partial<RouteDefinitions>) =>
  Object.entries(routeDefinitions)
    .filter(([key]) => configGetters[key])
    .map(([, value]) => value)

export const getReportsLinkMeta = () => configGetters.reportsLinkMeta

const isTaxsysAdapter = payablesAdaptor => /Taxsys-GovHub\/v/i.test(payablesAdaptor)
const allowDisplayTypeForTaxsysSite = displayType =>
  // Part of a new, medium-term workaround to enable a whole slew of features.
  (
    // In this case it enables these display types which are otherwise disabled
    // on taxsys sites
    displayType !== 'property-tax' &&
    displayType !== 'business-tax'
  ) ||
  configGetters.useTaxsysToGovhubFeatures

const getNormalizedPayableSources = () =>
  configGetters.payableSources
    ?.map((payableSource: Parameters<typeof normalizePayableSource>[0]) => normalizePayableSource(payableSource))
    // Don't allow navigation if taxsys source isn't enabled
    ?.filter(({ payablesAdaptor, displayType, route }) => route?.length &&
      (
        !isTaxsysAdapter(payablesAdaptor) ||
        allowDisplayTypeForTaxsysSite(displayType)
      ),
    ) || []

const getSearchPayableSources = () => getNormalizedPayableSources()
  .filter(({ searchType }) => searchType !== 'redirect' &&
    searchType !== 'taxsys-navigation-link')

const getRedirectPayableSources = () => getNormalizedPayableSources()
  .filter(({ searchType }) => searchType === 'redirect' ||
    searchType === 'taxsys-navigation-link')

const getPath = (route: string) => (
  configState.config.useClientOnlyUrl
    ? '/:client/'
    : `/:client/:site${configState.config.defaultSiteUrl && '?'}/`
) + route

const getForceShowPayableSourceFlag = (displayType: string): boolean => {
  const { client, site } = configState.config
  return configState.flags[`force-show-payable-source.${client}.${site}.${displayType}`]
}
const getHideSidebarPayableSourceFlag = (displayType: string): boolean => {
  const { client, site } = configState.config
  return configState.flags[`hide-sidebar-payable-source.${client}.${site}.${displayType}`]
}

const getEnableSiteInProdFlag = (to: RouteLocationNormalized): boolean => {
  const { client, site } = formatParams(to.params)
  return configState.flags[`enable-site-in-prod.${client}.${site}`]
}

type Component = NonNullable<Route['component']>

export const payableSearchRoutes = (component: Component) => getSearchPayableSources()
  .filter(({ displayType, hideInProd }) =>
    getForceShowPayableSourceFlag(displayType) || !isProd() || !hideInProd,
  )
  .map(payableSource => ({
    path: getPath(payableSource.route),
    name: payableSource.route,
    component,
    props: {
      searchType: payableSource.searchType,
      payablesAdaptor: payableSource.payablesAdaptor,
      icon: payableSource.icon,
      searchInputs: payableSource.searchInputs,
      exampleImage: payableSource.exampleImage,
      exampleImageText: payableSource.exampleImageText,
      exampleImageAltText: payableSource.exampleImageAltText,
      displayType: payableSource.displayType,
      blindDisplayNameLabel: payableSource.blindDisplayNameLabel,
      blindDisplayNamePlaceholder: payableSource.blindDisplayNamePlaceholder,
      expandComponentKey: payableSource.expandComponentKey,
      itemName: payableSource.itemName,
      generateUniqueId: payableSource.generateUniqueId,
      enableBulkAddToCart: payableSource.enableBulkAddToCart,
      bulkAddToCartFileFormat: payableSource.bulkAddToCartFileFormat,
      bulkAddToCartMaxItems: payableSource.bulkAddToCartMaxItems,
      bulkAddToCartContactTitle: payableSource.bulkAddToCartContactTitle,
      bulkAddToCartContactEmail: payableSource.bulkAddToCartContactEmail,
      displayBulkAddContact: payableSource.displayBulkAddContact,
      bulkAddToCartInformationalPacket: payableSource.bulkAddToCartInformationalPacket,
    },
    meta: {
      title: payableSource.title,
      subtitle: payableSource.subtitle,
      description: payableSource.description,
      displayType: payableSource.displayType,
      navOrder: payableSource.navOrder,
      navIcon: payableSource.icon,
      // Sometimes we add new payable sources but want to hide sidebar
      // access until a planned release.
      // - force-show-payable-source allows us to do session targeted prod
      //   testing. If true we should always show.
      // - hide-sidebar-payable-source and hideInProd essentially do the
      //   same thing but the site setting is a more robust and permanent
      //   solution. If either of those is on and we don't have force-show
      //   on then we should hide.
      linkInNav: getForceShowPayableSourceFlag(payableSource.displayType) ||
        !(
          getHideSidebarPayableSourceFlag(payableSource.displayType) ||
          (isProd() && payableSource.hideInProd)
        ),
      isSearchPage: true,
      pageImageTop: payableSource.pageImageTop,
      pageImageLeft: payableSource.pageImageLeft,
      route: payableSource.route,
    },
  }))

const bulkAddToCartComponentKeys = [
  'uploadPageComponent',
  'reviewPageComponent',
  'paymentOptionsPageComponent',
  'successPageComponent',
] as const

type BulkAddToCartComponents = Record<typeof bulkAddToCartComponentKeys[number], Component>

export const bulkAddToCartRoutes = (components: BulkAddToCartComponents) => payableSearchRoutes({})
  .filter(({ props: { enableBulkAddToCart } }) => enableBulkAddToCart)
  .map(payableSourceRoute => [
    {
      path: '/upload',
      name: '-upload',
      component: components.uploadPageComponent,
    },
    {
      path: '/upload/review',
      name: '-upload-review',
      component: components.reviewPageComponent,
    },
    {
      path: '/upload/confirmation',
      name: '-upload-confirmation',
      component: components.paymentOptionsPageComponent,
    },
    {
      path: '/upload/success',
      name: '-upload-success',
      component: components.successPageComponent,
    },
  ].map(({ path, name, component }) => ({
    ...payableSourceRoute,
    path: payableSourceRoute.path + path,
    name: payableSourceRoute.name + name,
    component,
    meta: {
      ...payableSourceRoute,
      linkInNav: false,
    },
  })))
  .flat()

export const taxsysIframeRoutes = (component: Component) => getSearchPayableSources()
  .filter(({ displayType, hideInProd, searchType, payablesAdaptor }) =>
    (getForceShowPayableSourceFlag(displayType) || !isProd() || !hideInProd) &&
    searchType === 'index-search' &&
    isTaxsysAdapter(payablesAdaptor),
  )
  .map(payableSource => ({
    path: `${getPath(payableSource.route)}/:encodedPayable/:taxsysSlugs(.*)*`,
    name: `${payableSource.route}-details`,
    component,
    props: {
      payablesAdapter: payableSource.payablesAdaptor,
      displayType: payableSource.displayType,
    },
    meta: {
      displayType: payableSource.displayType,
    },
    // 404 if the required taxsys setting isn't set
    beforeEnter (_to, _from, next) {
      if (!configState.config.taxsys?.taxsysClientId) {
        console.warn('No taxsysClientId set.')
        next({
          path: `${configState.config.client}/${configState.config.site}/404`,
        })
        return
      }

      next()
    },
  }))

export const siteUsesHomepage = () => configGetters.siteUsesHomepage
export const siteUsesRedirectOnly = () => configGetters.siteUsesRedirectOnly
export const siteUsesFormsOnly = () => configGetters.siteUsesFormsOnly

export const isStubSite = () => {
  const { client, site } = configState.config

  return configState.siteMeta[client]?.[site]?.type === 'stub'
}

export const isEpsRoute = (to: RouteLocationNormalized) =>
  configGetters.useEmbeddedPublicSite && to.meta?.disableForEPS

export const needsDemoSiteAuth = () =>
  configState.config?.payHub?.requireBasicAuth

export const needsAuth = (to: RouteLocationNormalized) =>
  !configGetters.useEmbeddedPublicSite &&
  (
    needsBasicAuth(GSG_ENVIRONMENT, formatParams(to.params).client) ||
    needsDemoSiteAuth()
  )

export const isMissingToSiteParam = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const { client: toClient, site: toSite } = formatParams(to.params)
  const { client: fromClient, site: fromSite } = formatParams(from.params)

  return fromClient === toClient && fromSite && !toSite
}

export const isUnknownSite = () => !configState.config ||
  Object.keys(configState.config).length === 0

export const isTsReportsRoute = (to: RouteLocationNormalized) =>
  configState.config?.defaultSiteUrl && formatParams(to.params).site === 'reports'

export const isUnknownDefaultSiteRoute = (to: RouteLocationNormalized) => {
  const toSite = formatParams(to.params).site
  return configState.config?.defaultSiteUrl && toSite && toSite !== configState.config.site
}

export const shouldRedirectClientOnlySite = (to: RouteLocationNormalized) => {
  const toSite = formatParams(to.params).site
  return configState.config.useClientOnlyUrl &&
  toSite &&
  toSite === configState.config.client
}

export const isUnknownClientOnlyRoute = (to: RouteLocationNormalized) => {
  const toSite = formatParams(to.params).site
  return configState.config.useClientOnlyUrl &&
    toSite &&
    to.name !== 'error' &&
    toSite !== configState.config.site
}

// Check if there is a LaunchDarkly flag for disabling a given site in prod. If
// so, and if that flag is explicitly set to false, disable the site in prod. Or
// if we have a enableInProd config that is set to false, disable the site. The
// LD flag will override the config.
export const isSiteDisabledInProd = (to: RouteLocationNormalized) => {
  const flag = getEnableSiteInProdFlag(to)
  return isProd() &&
  (
    flag === false ||
    (
      flag !== true &&
      configState.config.enableInProd === false
    )
  )
}

export const formatTitle = (to: RouteLocationNormalized, baseTitle: string) => {
  let title = to.matched.findLast(({ meta }) => meta && meta.title)
    ?.meta?.title?.[i18n?.global.locale.value] || ''

  if (baseTitle === 'clientTitle') {
    return title
  }

  if (title.length) {
    title += ' - '
  }

  return title + baseTitle
}

export const redirectNavLinks = () => getRedirectPayableSources()
  .map(payableSource => ({
    icon: payableSource.icon,
    type: payableSource.searchType,
    title: payableSource.title,
    link: payableSource.route,
    order: payableSource.navOrder,
    // Sometimes we add new payable sources but want to hide sidebar
    // access until a planned release.
    // - force-show-payable-source allows us to do session targeted prod
    //   testing. If true we should always show.
    // - hide-sidebar-payable-source and hideInProd essentially do the
    //   same thing but the site setting is a more robust and permanent
    //   solution. If either of those is on and we don't have force-show
    //   on then we should hide.
    linkInNav: payableSource.displayInNavbar &&
      (
        getForceShowPayableSourceFlag(payableSource.displayType) ||
        !(
          getHideSidebarPayableSourceFlag(payableSource.displayType) ||
          (isProd() && payableSource.hideInProd)
        )
      ),
  }))
