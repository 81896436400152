import type { Setting } from '@grantstreet/psc-config/src/types/settings.ts'
import safeLocalStorage from '@grantstreet/psc-js/utils/safe-local-storage.js'
import { GsgExternalLoginOptions, GsgFullLoginOptions } from '@grantstreet/login'
import type { User } from '@grantstreet/user'
import { GSG_ENVIRONMENT } from '@grantstreet/psc-environment'

export const setupEmbeddedLoginOptions = (user : User, embeddedWhisperer) : GsgExternalLoginOptions => ({
  user,
  useExternalAuth: true,
  getExternalJwt: () => embeddedWhisperer.message({
    action: 'gsgPublicSite.getJwt',
  }),
  handleLogin: () => embeddedWhisperer.notify({
    action: 'gsgPublicSite.handleLogin',
  }),
  userFieldMap: {
    name: 'name',
    givenName: 'given_name',
    familyName: 'family_name',
    phone: 'phone',
  },
})

export const setupFullLoginOptions = (user: User, config : Setting) : GsgFullLoginOptions => ({
  env: GSG_ENVIRONMENT,
  user,
  useExternalAuth: false,
  oidcClient: 'payhub-spa',
  getAuthQueryParams: () => {
    // Pass client data for display on the login screen
    const {
      client,
      site,
      payHub: {
        clientTitle: clientDisplay = '',
        landingPageTitle: siteDisplay = '',
        clientLogo = '',
      } = {},
    } = config

    // Discover the user's language preference
    const locale = safeLocalStorage.getItem('payhubDefaultLocale')

    return {
      'client_site': `${client}/${site}`,
      'client_display': clientDisplay,
      'site_display': siteDisplay,
      'display_logo_url': clientLogo,
      ...(locale ? { 'ui_locales': locale } : {}),
    }
  },
  userFieldMap: {
    name: 'name',
    givenName: 'given_name',
    familyName: 'family_name',
    phone: {
      current: 'https://govhub.com/phone',
      deprecated: 'https://pay-hub.net/phone',
    },
    contactPreference: 'contact_preference',
    language: {
      current: 'https://govhub.com/language',
      deprecated: 'https://pay-hub.net/language',
    },
  },
})
