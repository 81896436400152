import type { Store } from 'vuex'

// Parses the cartId from the URL. We can't use vue-router for this
// because it is not fully loaded until the app is mounted.
export async function setupCartIdFromRedirectUrl (store : Store<Record<string, unknown>>) {
  // We await the auth promise incase we're in the process of sending a newly
  // signed up user to a redirect from their confirmation email
  try {
    await store.getters['PayHub/authPromise']
  }
  catch (error) {}
  const urlForRedirectCartId = window.history.state.redirectCartId || window.location.pathname
  // Pull id from /:client/:site/redirect/:id or /:client/redirect/:id
  const match = urlForRedirectCartId.match('^/[^/]+(?:/[^/]+)?/redirect/([^/]+)')
  return match ? match[1] : undefined
}
