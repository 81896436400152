import store from './store/index.ts'
import type Payable from './models/Payable.ts'
import plugin from './install.ts'

// --- Plugin installer
export default plugin

// --- Dynamic Component Loader

export { default as PayablesLoader } from './PayablesLoader.vue'

// --- Methods

export {
  orderPayablesByPaths,
  encodePayablePath,
  getBulkAddToCartFileFormat,
  assignSearchFormat,
  localizePayableSource,
  normalizePayableSource,
  isSearchPage,
} from './utils.ts'
export { sanitizePayablePath } from './models/Payable.ts'

export const inflateDisplayType = ({ displayType }) =>
  store.getters.inflateDisplayType({ displayType })

export const getPayableSource = (displayType) =>
  store.getters.getPayableSource(displayType)

export const freeformFieldDefinitions = (payable) =>
  store.getters.freeformFieldDefinitions(payable)

export const mapFreeformFields = ({ payable, fieldMap }) =>
  store.getters.mapFreeformFields({ payable, fieldMap })

export const searchPayables = (...args) =>
  store.dispatch('searchPayables', ...args)

export const searchPayablesPath = (...args) =>
  store.dispatch('searchPayablesPath', ...args)

export const searchPayablesPaths = (...args) =>
  store.dispatch('searchPayablesPaths', ...args)

export const bulkSearchPayables = (...args) =>
  store.dispatch('bulkSearchPayables', ...args)

export const taxsysAddItems = (...args) =>
  store.dispatch('taxsysAddItems', ...args)

/**
 * createPayable will create a new payable using the "/convert" Payables
 * API endpoint.
 *
 * @param adaptor - The payable adaptor. A URL path (/Sunshine-PropertyTax/v0)
 * @param payable - The payable data.
 * @param accessToken - The access token for authorization
 * @param adminUserToken - A token identifying the user making the
 *    request, if different from the token used for access.
 * @returns A Promise that resolves to the created Payable
 */
export const createPayable = ({ adaptor, payable, accessToken = '', adminUserToken = '' }:{ adaptor: string, payable: {[key:string]: unknown}, accessToken?: string, adminUserToken?: string }) => {
  // XXX: This createPayable wrapper for export wraps the VueX store
  // createPayable action which wraps the API client's convert() method.
  // Is there a way to reduce the amount of wrapping we do so we don't
  // have to copy/paste documentation at each layer of wrapping?
  return store.dispatch('createPayable', { adaptor, payable, accessToken, adminUserToken }) as Promise<Payable>
}

// --- Models

export { default as Payable } from './models/Payable.ts'
export { default as BulkSearchPayables } from './models/BulkSearchPayables.ts'
export {
  default as BulkUploadConfig,
  PaymentOptionType,
} from './models/bulk-add-to-cart/BulkUploadConfig.ts'
export type { BulkUploadPaymentOption } from './models/bulk-add-to-cart/BulkUploadConfig.ts'
